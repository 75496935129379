.banner-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
}

.banner-container h2 {
    font-size: 36px;
    color: var(--yellow);
    font-family: 'Poppins',sans-serif;
}

.banner-container li{
    font-size: 15px;
    color: var(--light-color);
}

.banner-container h3 {
    font-size: 20px;
    color: var(--yellow);
    font-family: 'Poppins',sans-serif;
    margin-bottom: 2rem;
    width: 80%;
}

.banner-container button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 0;
    background-color: var(--yellow);
    box-shadow: var(--box-shadow);
    border-radius: 5px;    
    font-size: 1rem;
    font-weight: 600;
}


.banner-container ul{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin: 40px 0;
}

.banner-container ul li{
    position: relative;
    padding-left: 20px;
}

.banner-container ul li::before{
   position: absolute;
   width: 20px;
   height: 20px;
   left: -5px;
   top: 5px;
   content: '';
   z-index: 3;
    background: url(../../images/taxi-icon.png) no-repeat ;
    background-position: center;
   background-size: contain;

}

.download-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.download-right{
    width: 50%;
}

body.active .download-light-img,
body .download-dark-img{
    display: none;
    width: 100%;
    height: 100%;
}

body .download-light-img,
body.active .download-dark-img{
    display: flex;
    width: 100%;
    height: 100%;
}

@media (max-width:767px){
    .banner-container{
        flex-direction: column-reverse;
        padding: 0;
    }

    .download-left,
    .download-right{
        width: 100%;
    }

    .banner-container h3 {
        width: 100%;
    }

    .banner-container h2 {
        font-size: 24px;
        margin-top: 2rem;
    }

    .banner-container ul {
        row-gap: 10px;
        margin: 20px 0;
    }

    .banner-container h3 {
        font-size: 17px;
    }

    .download-btns {
        gap: 20px;
        flex-direction: row;
    }

    .banner-container button {
        font-weight: 500;
        gap: 5px;
    }
}