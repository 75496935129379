/* Container for the revolving strip */
.revolving-strip {
    width: 100%;
    height: 140px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  /* The moving strip containing multiple tickets */
 .revolving-strip .ticket-strip {
    display: flex;
    animation: scroll-strip 12s linear infinite;
  }
  
  /* Individual ticket styling */
 .revolving-strip .ticket {
    width: 280px;
    height: 120px;
    background: linear-gradient(135deg, #f57c00, #f4511e);
    border: 2px dashed rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    margin: 0 10px;
    position: relative;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    overflow: hidden;
  }
  
  /* Adding texture to the ticket */
 .revolving-strip .ticket:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
    opacity: 0.6;
    z-index: 1;
  }
  
  /* Ticket content */
 .revolving-strip .ticket-content {
    position: relative;
    z-index: 2;
    padding: 10px;
  }
  
  /* Header with icon */
 .revolving-strip .ticket-header {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
.revolving-strip  .ticket-header .icon {
    font-size: 1.2rem;
    animation: bounce 1s infinite alternate;
  }
  
  /* Discount text */
 .revolving-strip .discount {
    font-size: 1.5rem;
    font-weight: 800;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
  }
  
.revolving-strip  .discount .percent {
    font-size: 1.8rem;
    color: #ffd700;
  }
  
  /* Footer with validity text */
 .revolving-strip .ticket-footer {
    font-size: 0.8rem;
    margin-top: 5px;
    font-style: italic;
    opacity: 0.9;
  }
  
  /* Validity text styling */
 .revolving-strip .validity {
    background: rgba(255, 255, 255, 0.2);
    padding: 3px 8px;
    border-radius: 5px;
  }
  
  /* Perforation circles on ticket edges */
.revolving-strip  .perforation {
    position: absolute;
    top: 50%;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    transform: translateY(-50%);
    z-index: 1;
  }
  
.revolving-strip  .perforation.left {
    left: -7px;
  }
  
  .revolving-strip .perforation.right {
    right: -7px;
  }
  
  /* Animation for the strip */
  @keyframes scroll-strip {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Bounce animation for the icon */
  @keyframes bounce {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-5px);
    }
  }
  

@media (max-width: 1000px) {
  
    .revolving-strip {
        margin-top: 50px;
  }

}