.faq__services {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem 1rem;
  }
  
  .faq__services_title {
    font-size: 2.5rem;
    color: #ffb400;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .faq__services_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .faq__box {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    cursor: pointer;
    margin: 0 auto;
    transition: transform 0.2s ease, background 0.3s ease;
  }
  
  .faq__box:hover {
    transform: translateY(-3px);
    background: #fff4e0;
  }
  
  .faq__box.active {
    border-left: 4px solid #ffb400;
    background: #fff4e0;
  }
  
  .faq__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    color: #333;
  }
  
  .faq__content {
    margin-top: 1rem;
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    display: none;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }
  
  .faq__box.active .faq__content {
    display: block;
    max-height: 200px;
    opacity: 1;
  }
  
  @media (max-width: 700px) {
    .faq__services_title {
      font-size: 2rem;
    }
  
    .faq__box {
      font-size: 0.9rem;
    }
  
    .faq__content {
      font-size: 0.85rem;
    }
  }
  