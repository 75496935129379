.home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home .title {
    font-size: 30px;
    color: var(--yellow);
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.home__services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 70vh;
    width: 100%;
}

.home__services_title {
    font-size: 3rem;
    color: var(--yellow);
    margin: 4rem 0;
}

.home__services_container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    row-gap: 30px;
    flex-wrap: wrap;
}

.faq__box {
    padding: 15px 20px;
    width: 80%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    border-radius: 5px;
    padding: 10px;
    color: rgb(109, 109, 109);
    transition: height 1s ease;
    cursor: pointer;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    color: var(--yellow);
    border: 1px solid var(--black);
}
.faq__box h4{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
}

/* .faq__box.active h4 span:nth-child(2){
    transform: rotate(180deg);
} */

.faq__box p{
    height: 0%;
    display: none;
    transition: height 1s ease;
    width: 100%;
}

.faq__box.active p{
    display: block;
    transition: height 1s ease;
    height: 100%;
    padding: 10px;
}

.applink{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.download1 {
    z-index: 2;
    height: 550px;
    width: 45%;
    background: no-repeat;
    background-size: contain;
    background-image: url(../../images/apple-light.gif);
    animation: moveRight 1s linear forwards;
    opacity: 0;
    background-position: center;
}

body.active .download1 {
    background-image: url(../../images/apple-dark.gif);
}

.download2 {
    z-index: 2;
    height: 550px;
    width: 45%;
    background: no-repeat;
    background-size: contain;
    background-image: url(../../images/andriod-light.gif);
    animation: moveRight 1s linear forwards;
    opacity: 0;
    background-position: center;
}

body.active .download2 {
    background-image: url(../../images/andriod-dark.gif);
}

@media (max-width: 700px) {
.faq__box{
    width: 90%;
}
.home__services_title{
    font-size: 1.5rem;
}
.download1 ,.download2{
    height: 250px;
    width: 100%;
}

.applink {
    flex-direction: column;
}
}