.booking{
    position: relative;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.left-btn{
    margin-left: 10px;
}
.booking__container{
    position: relative;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 3rem;
    width: 100%;
}
.booking__left{
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.booking h2{
    width: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    color: var(--yellow);
    margin: 2rem 0;
}
.steps__container{
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.steps__container-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background:linear-gradient(180deg, #000,transparent 50%,#000);
}
.booking .right-arrow{
    font-size: 2rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.booking .left-arrow{
    font-size: 2rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.booking .slide{
    opacity: 0;
    transition-duration: 1s ease;
}

.booking .slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.booking .steps{
    width: 400px;
    margin: 2rem 0;
}

.steps h3{
    font-size: 18px;
    color: var(--black);
    font-weight: 400;
    width: 100%;
    text-align: center;
}
.booking  .right-arrow,  .booking .left-arrow {
    color: var(--black);
}
/* form */

.bookingForm{
    margin-top: 4rem;
    width: 45%;
}
.bookingForm h2{
    font-size: 48px;
    color: var(--yellow);
    width: 100%;
    text-align: center;
}
.booking_container{
    margin: 0 auto;
    margin-top: 5rem;
    width: 80%;
    border-radius: 6px;
    padding: 20px 60px 40px 40px;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.booking_container .middle-side{
    width: 50%;
    margin: 0 90px;
    position: relative;
}
.middle-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: var(--yellow);
}
.middle-side .input-box-combo{
    display: flex;
    justify-content: space-between;
}
.middle-side .input-box-combo .input-box{
    width: 45%;   
}
.middle-side .input-box input[type="checkbox"]{
width: 30px;
height: 30px;
margin-right: 10px;
}
.input-box-combo h3{
    color: var(--black);
}
.middle-side .input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
    display: flex;
}
.middle-side .input-box input:focus,
.middle-side .input-box textarea:focus,
.middle-side .input-box select:focus{
    border: none;
    outline: none;
}
.middle-side .input-box input,
.middle-side .input-box textarea,
.middle-side .input-box select{
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 6px;
    background-color: #f0f1f8;
    font-size: 16px;
    padding: 15px;
    resize: none;
}
.middle-side .message-box{
    min-height: 110px;
}
.middle-side input[type="submit"]{
    color: #fff;
    font-size: 18px;
    background: var(--yellow);
    outline: none;
    border-radius: 6px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}
.middle-side .button{
    margin-top: 20px;
}
.middle-side input[type="button"]:hover{
    background-color: var(--light-color);
}

.booking .download-btns  button {
    background: transparent;
}

.booking .download-btns{
    margin-top: 20px;
}

@media (max-width: 900px){
    .booking{
        flex-direction: column;
    }
    .booking__left{
        width: 90%;
    }
    .bookingForm{
        width: 90%;
    }
    .booking_container{
        width: 90%;
        height: 100%;
        padding: 20px 20px 40px 20px;
        flex-direction: column-reverse;
        margin-bottom: 2rem;
    }
    .booking_container .middle-side {
        width: 100%;
        margin: 0;
    }
    .middle-side .topic-text {
        margin: 10px 0;
    }
    .middle-side p {
        margin: 10px 0;
    }

    .booking__container{
        flex-direction: column;
    }
    .left-btn{
        margin-left: 0;
    }

}

@media (max-width: 700px){
    .booking h2{
        font-size: 28px;
    }
  
    }

@media (max-width:900px) {

    .booking h2{
        width: 100%;
    }

    .steps h3{
        font-size: 14px;
    }

    .booking .slide{
        margin: 0;
    }

    .booking .slide.active{
        margin: 0;
        width: 80%;
    }

    .steps{
        width: 80%;
    }

    .booking .right-arrow, .booking  .left-arrow {
        font-size: 2rem;
    }

    .booking .download-btns  a{
        width: 100%;
    }

    .booking .download-btns  {
        flex-wrap: wrap;
    }

}

/* ticket */

.steps__container .ticket {
    width: 450px;
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    position: relative;
  }
  
 .steps__container .ticket-header {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 2px dashed #ccc;
  }
  
 .steps__container .ticket-header h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
  }
  
 .steps__container .ticket-content {
    padding: 15px 10px;
  }
  
.steps__container  .ticket-info h3 {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #222;
  }
  
 .steps__container .ticket-info p {
    font-size: 0.9rem;
    color: #555;
  }
  
.steps__container  .ticket-divider {
    margin: 15px 0;
    border-top: 2px dashed #ccc;
  }
  
 .steps__container .ticket-footer {
    text-align: center;
    font-size: 0.8rem;
    color: #666;
  }
  