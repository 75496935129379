.contact {
    margin-top: 4rem;
}

.contact h2 {
    font-size: 48px;
    color: var(--yellow);
    width: 100%;
    text-align: center;
}

.contact_container {
    margin: 0 auto;
    margin-top: 2rem;
    width: 80%;
    border-radius: 6px;
    padding: 20px 60px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.contact_container .left-side {
    width: 25%;
    height: 100%;
    margin-top: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: moveLeft 1s linear forwards;
    opacity: 0;
}

@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translate(0px);
    }
}

@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translate(0px);
    }
}

/* .left-side::before{
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    background: #afafb6;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}
.middle-side::before{
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    background: #afafb6;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
} */
.left-side .details {
    margin: 14px;
    text-align: center;
}

.left-side .details i {
    font-size: 30px;
    color: var(--blue);
    margin-bottom: 10px;
}

.left-side .details .topic {
    font-size: 18px;
    font-weight: 500;
    color: var(--yellow);
}

.left-side .details .text-one,
.left-side .details .text-two {
    font-size: 14px;
    color: #afafb6;
}

.contact_container .middle-side {
    width: 50%;
    margin: 0 90px;
    position: relative;
}

.middle-side .topic-text {
    font-size: 23px;
    font-weight: 600;
    color: var(--yellow);
}

.middle-side .input-box {
    height: 50px;
    width: 100%;
    margin: 12px 0;
}

.middle-side .input-box input:focus,
.middle-side .input-box textarea:focus {
    border: none;
    outline: none;
}

.middle-side .input-box input,
.middle-side .input-box textarea {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 6px;
    background-color: #f0f1f8;
    font-size: 16px;
    padding: 15px;
    resize: none;
}

.middle-side .message-box {
    min-height: 110px;
}

.middle-side input[type="submit"] {
    color: #fff;
    font-size: 18px;
    background: var(--yellow);
    outline: none;
    border-radius: 6px;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.middle-side .button {
    margin-top: 20px;
}

.middle-side input[type="button"]:hover {
    background-color: var(--light-color);
}

.contact_map {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
}

.contact_map iframe {
    width: 100%;
    height: 100%;
}

.contact_content {
    display: flex;
    align-items: center;
}

.contact_content img {
    width: 40%;
}

.download {
    margin-left: 30px;
    z-index: 2;
    height: 350px;
    width: 450px;
    background: no-repeat;
    background-size: contain;
    background-image: url(../../images/home-banner-light.gif);
    animation: moveRight 1s linear forwards;
    opacity: 0;
}

body.active .download {
    background-image: url(../../images/home-banner-dark.gif);
}

.download3 {
    margin-left: 30px;
    z-index: 2;
    height: 550px;
    width: 100%;
    background: no-repeat;
    background-size: contain;
    background-image: url(../../images/contactLight.gif);
    animation: moveRight 1s linear forwards;
    opacity: 0;
    background-position: center;
}

body.active .download3 {
    background-image: url(../../images/contactDark.gif);
}

@media (max-width: 900px) {
    .contact_container {
        width: 90%;
        height: 100%;
        padding: 20px 20px 40px 20px;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .contact_container .left-side {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .left-side::before {
        display: none;
    }

    .contact_container .middle-side {
        width: 100%;
        margin: 0;
    }

    .middle-side .topic-text {
        margin: 10px 0;
    }

    .middle-side p {
        margin: 10px 0;
    }

    .contact_map {
        width: 100%;
    }

    .contact_content {
        display: flex;
        flex-direction: column;
    }

    .contact_content img {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .contact h2 {
        font-size: 28px;
    }

    .download {
        height: 320px;
        background-position: center;
    }

    .download3 {
        height: 250px;
        margin: 0;
    }
}